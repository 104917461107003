import { PlusCircleOutlined } from '@ant-design/icons';
import CSelect from '@components/UI/select/CSelect';
import PrimaryConditionsItemDynamic from './PrimaryConditionsItemDynamic';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Divider,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
const { Option } = Select;
function PrimaryConditionsDynamic({
  modalSetting,
  saveObjectCond,
  productSelectedQuotation,
  primaryCondForm,
  selectedObj,
  agrCond,
  dicti,
  quotationData,
  carForm,
}) {
  const [allRisk, setAllRisk] = useState<any>();
  const [risk, setRisk] = useState<any>();
  const [primaryItem, setPrimaryItem] = useState<any>();

  useEffect(() => {
    if (agrCond && selectedObj) {
      const carCond = agrCond.filter(
        (item) => Number(item.objisn) === Number(selectedObj.isn)
      );
      let riskList = {};
      if (carCond.length > 0) {
        setPrimaryItem(carCond);
        carCond.map((item, index) => {
          console.log(item);

          primaryCondForm.setFieldsValue({
            [index]: {
              ...item,
              date_beg: item?.datebeg ? dayjs(item.datebeg) : null,
              date_end: item?.dateend ? dayjs(item.dateend) : null,
              date_sign: item?.datesign ? dayjs(item.datesign) : null,
              early_rermination: item?.datedenounce
                ? dayjs(item?.datedenounce)
                : null,
              risk_isn: Number(item.riskisn),
              insurer_sum: item.limitsum,
              type: item.limitsumtype,
              premium_sum: item.premiumsum,
              limit: item.limitsumext,
              franch_tariff: item.franchtariff,
              franch_type: item.franchtype,
              franch_sum: item.franchsum,
              ins_type_isn: item.insclassisn,
              cond_isn: item.isn,
            },
          });
          if (riskList) {
            riskList = {
              ...riskList,
              [index]: item.agrrisk.map((riskItem) => {
                return {
                  ...riskItem,
                  risk_isn: riskItem.riskisn,
                  isn: riskItem.isn,
                  foreign_id: riskItem.isn,
                  risk_name: riskItem.riskisn_name,
                  franch_percent: riskItem.franchtariff,
                  loss_name: riskItem.damageclassisn_name,
                  remark: riskItem.remark,
                  franch_remark: riskItem.remark,
                };
              }),
            };
          } else {
            riskList = {
              [index]: item.agrrisk.map((riskItem) => {
                return {
                  ...riskItem,
                  risk_isn: riskItem.riskisn,
                  isn: riskItem.isn,
                  foreign_id: riskItem.isn,
                  risk_name: riskItem.riskisn_name,
                  franch_percent: riskItem.franchtariff,
                  loss_name: riskItem.damageclassisn_name,
                  remark: riskItem.remark,
                  franch_remark: riskItem.remark,
                };
              }),
            };
          }

          // setRisk({
          //   [index]: item.agrrisk.map((riskItem) => {
          //     return {
          //       ...riskItem,
          //       risk_isn: riskItem.riskisn,
          //       isn: riskItem.isn,
          //       foreign_id: riskItem.isn,
          //       risk_name: riskItem.riskisn_name,
          //       franch_percent: riskItem.franchtariff,
          //       loss_name: riskItem.damageclassisn_name,
          //       remark: riskItem.remark,
          //       franch_remark: riskItem.remark,
          //     };
          //   }),
          // });

          // setAllRisk({
          //   [index]: item.agrrisk.map((riskItem) => {
          //     return {
          //       ...riskItem,
          //       risk_isn: riskItem.riskisn,
          //       isn: riskItem.isn,
          //       foreign_id: riskItem.isn,
          //       risk_name: riskItem.riskisn_name,
          //       franch_percent: riskItem.franchtariff,
          //       loss_name: riskItem.damageclassisn_name,
          //       remark: riskItem.remark,
          //       franch_remark: riskItem.remark,
          //     };
          //   }),
          // });
        });
        console.log(riskList);

        setRisk(riskList);
        setAllRisk(riskList);
      } else {
        setPrimaryItem([modalSetting.item.card]);
      }
    }
  }, [agrCond]);
  // useEffect(() => {
  //   if (modalSetting && !primaryItem) {
  //     setPrimaryItem([modalSetting.item.card]);
  //   }
  // }, [modalSetting]);
  const addItem = () => {
    let max = primaryItem[0].fid;
    for (let i = 0; i < primaryItem.length; i++) {
      if (max < primaryItem[0].fid) {
        max = primaryItem[0].fid;
      }
    }
    setPrimaryItem([
      ...primaryItem,
      {
        fid: Number(max) + 1,
      },
    ]);
  };

  const deletePrimaryCond = (item) => {
    setPrimaryItem(
      primaryItem.map((elem) => {
        if (Number(elem.isn) === Number(item.isn)) {
          return {
            ...elem,
            operation: 'D',
          };
        } else {
          return elem;
        }
      })
    );
  };
  return (
    <>
      <Form className="c-form" layout="vertical" form={primaryCondForm}>
        <Row gutter={12}>
          {primaryItem?.map((item, index) => {
            // if (!item.operation && item.operation !== 'D') {
            return (
              <PrimaryConditionsItemDynamic
                primaryCondForm={primaryCondForm}
                productSelectedQuotation={productSelectedQuotation}
                modalSetting={modalSetting}
                quotationData={quotationData}
                selectedObj={selectedObj}
                risk={{ allRisk, risk, setAllRisk, setRisk }}
                primaryItem={primaryItem}
                item={item}
                index={index}
                key={`primaryCondItem-${index}`}
                carForm={carForm}
                saveObjectCond={saveObjectCond}
                deletePrimaryCond={deletePrimaryCond}
                isDelete={primaryItem.length > 1}
              />
            );
            // }
          })}
        </Row>
      </Form>

      <Divider plain>
        <PlusCircleOutlined
          style={{ fontSize: '26px', cursor: 'pointer' }}
          onClick={addItem}
        />
      </Divider>

      <Col span={24}>
        <Button
          type="primary"
          style={{ width: '100%' }}
          onClick={() => saveObjectCond(risk, setRisk)}
        >
          Сохранить
        </Button>
      </Col>
    </>
  );
}
export default PrimaryConditionsDynamic;
