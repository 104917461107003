import axios from "@services/api/axios";

interface ISendToDa {
  agr_isn: string | undefined;
  user_isn: string | undefined;
  text: string;
  node: number;
}

interface IGetPrintedForm {
  isn: string;
  userIsn: number;
}

interface IGetUrlPrintedForm {
  isn: string;
  userIsn: number;
  tempIsn: string;
}

interface IGetFileByUrl {
  fileName: string | undefined;
  url: string;
  format: string;
}

interface IIssueTreaty {
  agr_isn: number | undefined;
  user_isn: number;
}

const endpoints = {
  getListQuotations: () => axios.get('/full-editor'),
  getEmptyQuotation: (data) => axios.post('/quotation/empty', data),
  getQuotationStatuses: () => axios.get('/dicti/card/status'),
  getListExpressQuotation: () => axios.post('/express-product'),
  getCompanyTree: () => axios.post('/tree/company'),
  getFullQuotation: (productIsn) => axios.get(`/full/products/${productIsn}`),
  getDocsTypes: (data) => axios.post('/tariff/docs', data),
  getCommunication: () => axios.get('/dicti/card/communication'),

  getPrintedForm: (data: IGetPrintedForm) =>
    axios.post('/quotation/prints', data),
  getUrlByPrintedFormISN: (data: IGetUrlPrintedForm) =>
    axios.post('/quotation/print/url', data),
  getFileByUrl: (data: IGetFileByUrl) =>
    axios.post('/quotation/print/file', data, { responseType: 'blob' }),

  getPpk: (agrIsn: number | undefined) =>
    axios.post('/quotation/get/ppk', { agr_isn: agrIsn }),
  setGraph: (data) => axios.post('/quotation/set/graph', data),
  getGraph: (data: IIssueTreaty) => axios.post('/quotation/get/graph', data),
  setGraphAgr: (data) => axios.post(`/quotation/set/graphByAgrIsn`, data),
  getGraphAgr: (data) => axios.get(`/quotation/get/graphByAgrIsn/${data}`),
  resetGraph: (data) => axios.post('/quotation/graph/delete' , data),
  getCities: () => axios.get('/get/city'),
  getMembers: () => axios.get('/get/roles'),

  createIssueContract: (data: IIssueTreaty) =>
    axios.post('/quotation/agr/doc', data),
  buildContract: (data: IIssueTreaty) =>
    axios.post('contract/createAndIssueAgrfromCalc', data),
  searchInn: (data) => axios.post('/search/iin', data),
  searchCar: (data) => axios.post('/search/car', data),
  searchAttributeMRP: (data) => axios.post('/tariff/attribute/mrp', data),
  saveCounterpartyInsurant: async (data) => {
    let result;
    await axios
      .post('/quotation/add', data)
      .then((success) => (result = success))
      .catch((error) => {
        console.log(error);
        if (error.response) {
          result = error.response;
        } else if (error.request) {
          result = error.request;
        } else {
          result = error.message;
        }
      });
    return result;
  },
  saveCounterparty: (data) => axios.post('/quotation/role/save', data),
  sendToDa: (data) => axios.post('/quotation/set/req', data),

  deleteCounterParty: (data) => axios.post('/role/delete', data),
  deleteObject: (data) => axios.post('/object/delete', data),

  setAgrCond: (data: any) => axios.post('/quotation/cond', data),
  setAgrCalculate: (data: any) => axios.post('/quotation/calculate', data),

  searchQuotation: (data: ISendToDa) => axios.post('/quotation/search ', data),
  searchAgreement: (data: ISendToDa) => axios.post('/quotation-contract/search ', data),
  getFullQuotationJournal: (values) =>
    axios.post('quotation/update', values),

  updFullQuotation: (id, data) => axios.put(`/quotation/full/${id}`, data),
  updEmptyQuotation: (data) => axios.post('/quotation/empty/update', data),
  sendToFullQuotation: (data) => console.log(data),
  getReqType: (data) => axios.post('/quotation/getServReqTypes', data),
  saveRequest: (data) => axios.post('/quotation/setCalcServReq' , data),
  contractProlongation: (data) => axios.post('/quotation/prolongation' , data),
  saveQuotationRisk: (data) => axios.post('/quotation/agr_risk' , data),

  setDocButton: (data) => axios.post('quotation/docs/setDocButton ', data),
  setDocInfo: (data) => axios.post('quotation/docs/set', data),
  deleteRow: (data) => axios.post('quotation/docs/row/delete', data),
  createContractWithoutQuotation: (data) => axios.post('contract/set-contract', data),
  saveContractToESBD: (data) => axios.post('contract/saveToESBD' , data)
};

export default endpoints;
