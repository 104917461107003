import React, { memo, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './MainQuotation.scss';
import {
  Col,
  Form,
  Row,
  Typography,
  Spin,
  notification,
  Button,
  Modal,
} from 'antd';
import axios from 'axios';
import api from '@modules/Quotation/services';
import useAuth from '../../../hooks/useAuth';
import ObjectQuotation from '../ObjectQuotation/index';
import { IMainQuotation } from '../Interfaces/MainQuotation/index.interface';
import AdditionalAttributes from '../AdditionalAttributes';
import Clauses from '../ClausesQuotation';
import FilesQuotation from '../Files';
import { useImmerReducer } from 'use-immer';
import MembersQuotation from '../MembersQuotation/index';
import InsurerQuotation from '../InsurerQuotation';
import { validateForms } from '../MainButtons/utils/validateForms';
import PrintedForm from '../PrintedForm/PrintedForm';
import ContractDocument from '../ContractDocuments';
import useQuotation from '../hooks/useQuotation';
import useQuotationJournal from '../hooks/useQuotationJournal';
import FormularQuotation from '../FormularQuotation';
import SearchClientModal from '@modules/common/Client/SearchClientModal';
import ResultClientModal from '@modules/common/Client/ResultClientModal';
import AddClientModal from '@modules/common/Client/AddClientModal';
import UnderWritingDepartment from '@modules/Quotation/UnderWritingDepartment';
import MainButtons from '@modules/Quotation/MainButtons';
// @ts-ignore
import ObjectQuotationStatic from '@modules/Quotation/ObjectQuotation/ObjectQuotationStatic';
import { TypeAction } from '@modules/Quotation/providers/DateReducer';
import dayjs from 'dayjs';
import RequestQuotaion from '@modules/Quotation/RequestQuotaion';
import contractorFunctions from '@modules/common/Client/Functions';
import { LoadingOutlined } from '@ant-design/icons';
import StatusQuotation from '@modules/Quotation/StatusQuotation';
import notificationBingGrey from '../../../assets/notification-bing.svg';
import notificationBingWhite from '../../../assets/notification-bing-white.svg';
import NotificationsModal from '@modules/Quotation/NotificationsModal';
import CButton from '@components/UI/button/CButton';
import { IPrintFormData } from '../PrintedForm/PrintedForm';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import ObjectQuotationDynamic from '../ObjectQuotation/ObjectQuotationDynamic';

const { Title, Text } = Typography;

type Action = {
  type:
    | 'nextStep'
    | 'backStep'
    | 'showSearchContractorModal'
    | 'hideContractorModal'
    | 'getContractorInfo'
    | 'searchContractor'
    | 'saveContractorList'
    | 'selectContractor'
    | 'selectRelatedContractor'
    | 'search'
    | 'openRelatedInfo'
    | 'saveQuotationData'
    | 'removeRelatedInfo'
    | 'removeContractInfo'
    | 'saveMemeberSettings'
    | 'isUpdate';
  payload?: any;
};

interface State {
  step: number;
  isVisibleConstactorModal: boolean;
  contractorInfo: any[] | null;
  contractorList: [] | null;
  contractorFullInfo: {} | null;
  isLoading: boolean;
  isJurUser: boolean;
  from: any;
  isRelatedOpen: boolean;
  selectRelatedContractor: any;
  relatedInfo: any;
  quotationData: any;
  isUpdate: boolean;
  memberSettings: any;
}
const initialState: State = {
  step: 0,
  isVisibleConstactorModal: false,
  contractorInfo: null,
  contractorList: null,
  contractorFullInfo: null,
  isLoading: false,
  isJurUser: true,
  from: null,
  isRelatedOpen: false,
  selectRelatedContractor: null,
  relatedInfo: null,
  quotationData: null,
  isUpdate: false,
  memberSettings: null,
};

const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'saveQuotationData': {
      draft.quotationData = action.payload;
      break;
    }
    case 'saveMemeberSettings': {
      draft.memberSettings = action.payload;
      break;
    }
    case 'showSearchContractorModal': {
      draft.step = 1;
      draft.isVisibleConstactorModal = true;
      break;
    }
    case 'searchContractor': {
      draft.step = 2;
      break;
    }
    case 'selectRelatedContractor': {
      draft.selectRelatedContractor = action.payload;
      draft.isRelatedOpen = false;
      draft.isJurUser = true;
      draft.from = null;
      break;
    }
    case 'saveContractorList': {
      draft.isJurUser = action.payload.isJurUser == 'Y';
      draft.contractorList = action.payload.data;
      break;
    }
    case 'hideContractorModal': {
      draft.step = 0;
      break;
    }
    case 'openRelatedInfo': {
      draft.isRelatedOpen = action.payload.show;
      draft.relatedInfo = action.payload.data;
      draft.isJurUser = true;
      break;
    }
    case 'selectContractor': {
      draft.isJurUser = action.payload.juridical == 'Y';
      draft.contractorFullInfo = action.payload.data;
      if (action.payload.data) {
        draft.contractorInfo = [
          {
            FULLNAME: action.payload.data.fullname,
            IIN: action.payload.data.iin,
            BIRTHDAY: action.payload.data.birthday,
            ISN: action.payload.data.isn,
          },
        ];
      }
      break;
    }
    case 'removeRelatedInfo': {
      draft.relatedInfo = null;
      break;
    }
    case 'getContractorInfo': {
      draft.contractorInfo = action.payload;
      break;
    }
    case 'removeContractInfo': {
      draft.contractorInfo = null;
      draft.contractorFullInfo = action.payload;
      if (action.payload?.jur) {
        draft.isJurUser = action.payload.jur == 'Y';
      }
      break;
    }
    case 'nextStep': {
      if (action.payload.step) {
        draft.step = action.payload.step;
      } else {
        draft.step = action.payload;
      }
      if (action.payload.from) {
        draft.from = action.payload.from;
      }
      break;
    }
    case 'backStep': {
      draft.step = draft.step - 1;
      break;
    }
    case 'search': {
      draft.isLoading = action.payload;
      break;
    }
    case 'isUpdate': {
      draft.isUpdate = action.payload;
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

const MainQuotation: React.FC<IMainQuotation> = memo(() => {
  const [form] = Form.useForm();
  const [clauseForm] = Form.useForm();
  const [formularForm] = Form.useForm();
  const [membersForm] = Form.useForm();
  const [additionalAttributesForm] = Form.useForm();
  const [objectForm] = Form.useForm();
  const [fileForm] = Form.useForm();
  const [documentForm] = Form.useForm();
  const [insurerForm] = Form.useForm();
  const [requestQuotaionForm] = Form.useForm();
  const { quotationId } = useParams();
  const [productIsn, setProductIsn] = useState();
  const [agrIsn, setAgrIsn] = useState(0);
  const [productName, setProductName] = useState('');
  const [flow, setFlow] = useState([]);
  const [openNotification, setOpenNotifications] = useState(false);
  const [isShowAttr, setIsShowAttr] = useState<boolean>(false);

  const validateFormsArray = [
    insurerForm,
    formularForm,
    additionalAttributesForm,
    clauseForm,
    objectForm,
  ];
  const {
    setProductSelectedQuotation,
    setAgrContractor,
    setAgrClauses,
    setAgrAttributesUnder,
    setAgrAttributes,
    setAgrMembers,
    dispatchDate,
    agrObj,
    setAgrObj,
    stateDateQuotation,
    setFileList,
    agrContractor,
    productSelectedQuotation,
    agrCond,
    setAgrCond,
    setMemberSettings,
    memberSettings,
  } = useQuotation();
  const [stateSteps, dispatch] = useImmerReducer(reducer, initialState);
  const {
    isVisibleConstactorModal,
    isRelatedOpen,
    relatedInfo,
    contractorInfo,
    step,
    contractorList,
    isLoading,
    contractorFullInfo,
    isJurUser,
    from,
    selectRelatedContractor,
    quotationData,
    isUpdate,
  } = stateSteps;

  useEffect(() => {
    document.title = `Котировка ${productName} #${quotationId}`;
  }, []);
  const { state } = useQuotationJournal();
  const auth = useAuth();
  const [userForeignId, setUserForeignId] = useState(0);
  const [attributesList, setAttributesList] = useState([]);
  const [objAttributesList, setObjAttributesList] = useState([]);
  const [objAdditionalAttributesList, setObjAdditionalAttributesList] =
    useState([]);
  const [objUnderAttributesList, setObjUnderAttributesList] = useState([]);
  const [isDynamicProduct, setIsDynamicProduct] = useState(false);

  useEffect(() => {
    setUserForeignId(auth.user?.data?.user_info.foreign_id);
  }, [auth]);
  // TODO: копипаста - удалить из CreateQuotation или вынести в модуль
  async function getQuotationFieldsAndDataByIdAndSetResponse() {
    const { data } = await api.quotation.getFullQuotation(productIsn);
    setIsDynamicProduct(
      productIsn !== 471541 &&
        productIsn !== 474651 &&
        productIsn !== 474641 &&
        productIsn !== 474741 &&
        productIsn !== 471651 &&
        productIsn !== 437401
    );
    setAttributesList(data.data[0]?.attributes);
    setObjAttributesList(data.data[0]?.objects_attr);
    if (data.data[0]?.objects_attr) {
      setObjAdditionalAttributesList(data.data[0]?.objects_attr[0]);
    }
    if (data.data[0]?.objects_attr[1]) {
      setObjUnderAttributesList(data.data[0]?.objects_attr[1]);
    }
    setProductSelectedQuotation(data.data);
    setMemberSettings(
      data.data[0].members.filter((memb) => Number(memb.foreign_id) === 221507)
    );
  }

  /**
   * Подтягиваем сохраненные данные полей
   */
  const getFullData = async () => {
    const payloadData = {
      user_isn: auth.user?.data?.user_info.foreign_id, //state?.agrDate?.user_isn
      quotation_id: quotationId,
    };
    try {
      dispatch({ type: 'isUpdate', payload: true });
      const { data } = await api.quotationJournal.getFullQuotationJournal(
        payloadData
      );
      if (Array.isArray(data?.data?.flow)) {
        setFlow(data?.data?.flow);
      } else {
        setFlow(Object.values(data?.data?.flow));
      }
      dispatch({ type: 'saveQuotationData', payload: data.data });
      const quotationData = data.data.full_quotation[0];

      setProductIsn(quotationData.product_id);
      setAgrIsn(Number(quotationData?.id));
      setProductName(quotationData.product_name);
      /**
       * Заполняем объекты
       */
      if (data.data.objects) {
        // if (Number(data.data.full_quotation[0].product_id) === 471541) {
        //   setAgrObj(data.data.objects);
        // } else {
        Object.keys(data.data.objects).map((key) => {
          data.data.objects[key].map((item) => {
            if (agrObj.length === 0) {
              setAgrObj([item]);
            } else {
              setAgrObj([...agrObj, item]);
            }
          });
        });

        // }
      }
      if (data.data.agrcond.length) {
        if (Number(data.data.full_quotation[0].product_id) === 471541) {
          setAgrCond(data.data.agrcond);
        } else {
          console.log(data.data);

          setAgrCond(data.data.agrcond);
        }
      }

      /**
       * Заполняем Атрибуты (Раздел страховщика/Андеррайтера)
       */
      const attributesData = data?.data?.attributes;
      if (attributesData) {
        console.log(attributesData);

        setAgrAttributes(attributesData);
        attributesData.attributes_insurer.map((item) => {
          if (item.attr_isn) {
            if (item.attr_isn_code === 'DATE') {
              additionalAttributesForm.setFieldValue(
                item.attr_isn,
                dayjs(item.value, 'DD.MM.YYYY')
              );
            } else if (item.attr_isn_code === 'SUBJECT') {
              additionalAttributesForm.setFieldValue(
                item.attr_isn,
                Number(item.val_n)
              );
            } else if (item.attr_isn_code === 'DATETIME') {
              additionalAttributesForm.setFieldValue(
                item.attr_isn,
                dayjs(item.value, 'DD.MM.YYYY HH:mm:ss')
              );
            } else if (item.attr_isn_code === 'DICTI_CODENAME' && item.val_n) {
              additionalAttributesForm.setFieldValue(
                item.attr_isn,
                Number(item.val_n)
              );
            } else if (item.attr_isn_code === 'DICTI') {
              if (Number(item.attr_isn) === 2656251) {
                if (Number(item.val_n) === 482291) {
                  setIsShowAttr(true);
                } else {
                  setIsShowAttr(false);
                }
              }
              additionalAttributesForm.setFieldValue(
                item.attr_isn,
                Number(item.val_n)
              );
            } else if (item.attr_isn_code === 'TEXT') {
              additionalAttributesForm.setFieldValue(item.attr_isn, item.value);
            } else if (item.attr_isn_code === 'CHECKBOX') {
              additionalAttributesForm.setFieldValue(item.attr_isn, item.val_n);
            } else if (item.attr_isn_code === 'INTEGER') {
              additionalAttributesForm.setFieldValue(item.attr_isn, item.val_n);
            } else {
              additionalAttributesForm.setFieldValue(
                item.attr_isn,
                item.value || item.val_c || Number(item.val_n)
              );
            }
          }
        });
        attributesData.attributes_under.map((item) => {
          if (item.attr_isn) {
            if (item.attr_isn_code === 'DATE') {
              additionalAttributesForm.setFieldValue(
                item.attr_isn,
                dayjs(item.value, 'DD.MM.YYYY')
              );
            } else if (item.attr_isn === 2656251) {
              additionalAttributesForm.setFieldValue(item.attr_isn, item.val_n);
            } else if (item.attr_isn_code === 'DICTI_CODENAME') {
              additionalAttributesForm.setFieldValue(item.attr_isn, item.val_n);
            } else if (item.attr_isn_code === 'DICTI') {
              additionalAttributesForm.setFieldValue(item.attr_isn, item.val_n);
            } else if (item.attr_isn_code === 'TEXT') {
              additionalAttributesForm.setFieldValue(item.attr_isn, item.value);
            } else if (item.attr_isn_code === 'CHECKBOX') {
              additionalAttributesForm.setFieldValue(item.attr_isn, item.val_n);
            } else {
              additionalAttributesForm.setFieldValue(
                item.attr_isn,
                item.value || item.val_c || item.val_n
              );
            }
          }
        });
      }

      if (data?.data?.attributes_under) {
        setAgrAttributesUnder(data?.data?.attributes_under);
      }
      /**
       * Заполняем Ограничения/Оговорки
       */
      if (data?.data?.clauses) {
        setAgrClauses(data?.data?.clauses);
      }

      /**
       * Заполняем Участников
       */
      // const membersData = data?.data?.members;
      // console.log(memberSettings);

      // if (membersData?.length > 0) {
      //   // Если только один участник и это Страхователь - докидываем ему пустых т.к. он не показывается
      //   setAgrMembers(membersData);
      //   // else {
      //   // Добавляем пару пустых участника
      //   // setAgrMembers(memberSettings);
      // }

      /**
       * Заполняем страхователя
       */
      if (quotationData?.client_iin) {
        setAgrContractor(quotationData);
        const contractorData = [
          {
            ISN: quotationData.client_id,
            IIN: quotationData.client_iin,
            EXTSYSTEMKEY: data?.data?.extsystemkey,
            FULLNAME: quotationData.client_name,
            JURIDICAL: quotationData.client_jur,
            BIRTHDAY: quotationData.client_birthday,
          },
        ];
        dispatch({
          type: 'getContractorInfo',
          payload: contractorData,
        });
      }

      /**
       * Заполняем Формуляр
       */
      if (quotationData?.date_sign) {
        dispatchDate({
          type: TypeAction.DATE_SIGN,
          payload: dayjs(quotationData.date_sign).format('YYYY-MM-DD'),
        });
      }

      if (quotationData?.date_beg) {
        dispatchDate({
          type: TypeAction.DATE_BEG,
          payload: dayjs(quotationData.date_beg).format('YYYY-MM-DD'),
        });
      }

      if (quotationData?.date_end) {
        dispatchDate({
          type: TypeAction.DATE_END,
          payload: dayjs(quotationData.date_end).format('YYYY-MM-DD'),
        });
      }

      if (quotationData?.remark) {
        dispatchDate({
          type: TypeAction.REMARK,
          payload: quotationData.remark,
        });
      }
      if (quotationData?.agr_status) {
        dispatchDate({
          type: TypeAction.AGR_STATUS,
          payload: quotationData.agr_status,
        });
      }

      if (quotationData?.status_id) {
        dispatchDate({
          type: TypeAction.STATUS_ISN,
          payload: quotationData.status_id,
        });
      }

      if (quotationData?.curr_id) {
        dispatchDate({
          type: TypeAction.CURRENCY,
          payload: quotationData.curr_id,
        });
      }

      /**
       * Получаем прикрепленные файлы
       */
      if (data?.data?.kias_files.length) {
        setFileList(data?.data?.kias_files);
      }
    } catch (error) {
      let message;
      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }
      console.log(error);

      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      dispatch({ type: 'isUpdate', payload: false });
    }
  };

  /**
   * Проверяет страхователь юр. или физ. лицо
   */
  const isJuridical = useMemo(() => {
    if (contractorInfo?.length) {
      return contractorInfo[0]?.JURIDICAL === 'Y';
    } else if (agrContractor) {
      return agrContractor?.client_jur === 'Y';
    }
    return undefined;
  }, [contractorInfo, agrContractor]);

  useEffect(() => {
    if (productIsn) {
      getQuotationFieldsAndDataByIdAndSetResponse();
    }
  }, [productIsn]);
  useEffect(() => {
    if (quotationId && auth.user?.data?.user_info.foreign_id && !productIsn) {
      getFullData();
    }
  }, [memberSettings, quotationId, auth, productIsn]);
  const searchContractor = async () => {
    dispatch({ type: 'search', payload: true });
    const { iin } = insurerForm.getFieldsValue();
    let returnData;
    try {
      if (iin && iin.length == 12) {
        let reqBody;

        if (productIsn == 474651) {
          reqBody = {
            jur: 'N',
          };
        }
        const { data } = await api.contractor.getContractorByIIN({
          ...reqBody,
          iin,
        });
        if (data.data.length == 1) {
          if (!data.data[0].ISN) {
            const info = await api.contractor.saveContractorKias({
              xml_isn: data.data[0].XMLISN,
              ext_system_key: data.data[0].EXTSYSTEMKEY,
            });
            dispatch({
              type: 'getContractorInfo',
              payload: [{ ISN: info.data.isn, ...data.data[0] }],
            });
            returnData = { ISN: info.data.isn, ...data.data[0] };
          } else {
            dispatch({
              type: 'getContractorInfo',
              payload: data.data,
            });
            returnData = data.data[0];
          }
        } else {
          dispatch({ type: 'nextStep', payload: 2 });
          dispatch({
            type: 'saveContractorList',
            payload: { data: data.data },
          });
          returnData = { data: data.data[0] };
        }
      } else {
        notification.error({
          message: 'Ошибка',
          description: 'Введите ИИН / БИН',
        });
      }
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      notification.error({
        message: 'Ошибка',
        description: message,
      });

      dispatch({ type: 'showSearchContractorModal', payload: true });
    }
    dispatch({ type: 'search', payload: false });
    return returnData;
  };
  const getContractorList = async (values) => {
    contractorFunctions.getContractorList({ dispatch, values });
  };

  const handleCancelAddClientModal = () => {
    dispatch({ type: 'hideContractorModal', payload: 0 });
    dispatch({ type: 'nextStep', payload: { from: null } });
  };
  const handleRemoveContractorInfo = () => {
    dispatch({ type: 'removeContractInfo', payload: null });
  };
  const selectContractor = async (value, fromInfo) => {
    try {
      dispatch({ type: 'search', payload: true });
      if (!value.ISN && !value.isn) {
        const info = await api.contractor.saveContractorKias({
          xml_isn: value.XMLISN,
          ext_system_key: value.EXTSYSTEMKEY,
        });
        const { data } = await api.contractor.getContractorByISN(info.data.isn);

        if (fromInfo == 'related') {
          dispatch({ type: 'selectRelatedContractor', payload: data.data });
        } else {
          setAgrContractor({
            ...agrContractor,
            client_id: data.data.isn,
          });

          const requestBody = {
            connection: 'centras',
            agr_isn: agrIsn,
            user_isn: userForeignId,
            client_id: String(data.data.isn),
            date_sign: stateDateQuotation.date_sign,
            date_beg: stateDateQuotation.date_beg,
            date_end: stateDateQuotation.date_end,
            status_isn: stateDateQuotation.status_isn,
            remark: stateDateQuotation.remark,
          };

          const info = await api.quotation.saveCounterpartyInsurant(
            requestBody
          );
          dispatch({
            type: 'selectContractor',
            payload: { data: data.data, juridical: data.data.juridical },
          });
        }
      } else {
        const isn = value.ISN ? value.ISN : value.isn;
        const { data } = await api.contractor.getContractorByISN(isn);
        if (fromInfo === 'related') {
          dispatch({ type: 'selectRelatedContractor', payload: data.data });
        } else {
          setAgrContractor({
            agr_isn: agrContractor.id,
            bonus_malus_isn: null,
            class_isn: '2103',
            isn: null,
            order_no: null,
            remark: null,
            user_isn: agrContractor.user_isn,
            client_birthday: data.data.birthday,
            client_id: data.data.isn,
            client_name: data.data.fullname,
            client_iin: data.data.iin,
          });

          const requestBody = {
            connection: 'centras',
            agr_isn: agrIsn,
            user_isn: userForeignId,
            client_id: String(data.data.isn),
            date_sign: stateDateQuotation.date_sign,
            date_beg: stateDateQuotation.date_beg,
            date_end: stateDateQuotation.date_end,
            status_isn: stateDateQuotation.status_isn,
            remark: stateDateQuotation.remark,
          };

          const info = await api.quotation.saveCounterpartyInsurant(
            requestBody
          );

          dispatch({
            type: 'selectContractor',
            payload: { data: data.data, juridical: data.data.juridical },
          });
        }
      }

      dispatch({ type: 'nextStep', payload: 3 });
      dispatch({ type: 'search', payload: false });
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      dispatch({ type: 'search', payload: false });
      notification.error({
        message: 'Ошибка',
        description: message,
      });
    }
  };
  const updateContractorInfo = async (value) => {
    // console.log(value);
    // setAgrContractor(value)

    dispatch({
      type: 'selectContractor',
      payload: { data: value, juridical: value.juridical },
    });
  };

  const AdvancedContractorSearch = () => {
    dispatch({ type: 'nextStep', payload: 1 });
  };
  const createContractor = () => {
    dispatch({ type: 'nextStep', payload: 3 });
  };
  const getQuotationId = () => {
    if (quotationId) return quotationId;
  };

  const changeState = (step, data: any | null, fromInfo) => {
    dispatch({ type: 'nextStep', payload: { step, from: fromInfo } });
    if (data !== null) {
      dispatch({ type: 'saveContractorList', payload: { data } });
    }
    if (fromInfo === 'related') {
      dispatch({
        type: 'removeContractInfo',
        payload: { ...contractorFullInfo, from: 'related', jur: 'Y' },
      });
    }
  };
  const showRelatedInfo = async (info) => {
    try {
      if (info) {
        if (info.headisn) {
          const { data } = await api.contractor.getContractorByISN(
            info.headisn
          );
          dispatch({
            type: 'openRelatedInfo',
            payload: { data: data.data, show: true },
          });
        } else if (info.isn) {
          const { data } = await api.contractor.getContractorByISN(info.isn);
          dispatch({
            type: 'openRelatedInfo',
            payload: { data: data.data, show: true },
          });
        }
      }
      notification.success({
        message: 'Сохранено',
        description: 'Данные успешно сохранены',
      });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: 'Что-то пошло не так',
      });
    }
    return false;
  };
  const closeRelatedInfo = () => {
    dispatch({
      type: 'openRelatedInfo',
      payload: { data: null, show: false },
    });
  };

  let formularTimeout;
  /**
   * При изменении полей формуляра отправляем данные с задержкой после ввода
   * @param fieldName - имя поля, которое изменяли
   * @param fieldValue - значение поля, которое меняли
   */
  const onFormularChange = (fieldData) => {
    const fieldName: any = Object.keys(fieldData)[0];
    const fieldValue: any = Object.values(fieldData)[0];
    clearTimeout(formularTimeout);
    formularTimeout = setTimeout(() => {
      if (!agrContractor.client_id) return;
      const requestBody = {
        connection: 'centras',
        agr_isn: agrIsn,
        user_isn: userForeignId,
        client_id: String(agrContractor.client_id),
        date_sign: dayjs(
          formularForm.getFieldValue(TypeAction.DATE_SIGN)
        ).format('YYYY.MM.DD'),
        date_beg: dayjs(formularForm.getFieldValue(TypeAction.DATE_BEG)).format(
          'YYYY.MM.DD'
        ),
        date_end: dayjs(formularForm.getFieldValue(TypeAction.DATE_END)).format(
          'YYYY.MM.DD'
        ),
        status_isn: formularForm.getFieldValue(TypeAction.STATUS_ISN),
        remark: formularForm.getFieldValue(TypeAction.REMARK),
      };
      (async () => {
        const { data } = await api.quotation.saveCounterpartyInsurant(
          requestBody
        );
        if (data.success === true) {
          // setAgrContractor(data.data);

          notification.success({
            message: 'Сообщение',
            description: 'Данные успешно сохранены',
          });
          const dates = ['DATE_BEG', 'DATE_END', 'DATE_SIGN'];
          if (!dates.includes(fieldName)) {
            dispatchDate({
              type: fieldName,
              payload: fieldValue,
            });
          }
        } else {
          notification.info({
            message: 'Ошибка',
            description: data.error,
          });

          // Если ошибка то Возвращаем предыдущее значение (актуально для Статуса)
          formularForm.setFieldValue(
            fieldName,
            stateDateQuotation[fieldName.toLowerCase()]
          );
        }
      })();
    }, 1000);
  };
  return (
    <div className="main-quotation" style={{ paddingBottom: 200 }}>
      <CustomLoader spinning={!productName || !quotationId || !agrIsn}>
        <Row gutter={[24, 24]}>
          <Col xs={22} xl={22}>
            <Title level={1}>
              Котировка {productName} #{quotationId} (
              {agrContractor?.status_name})
              {quotationData?.full_quotation[0].old_agr_isn.length > 0 && (
                <Text className="subtitle-quotation">
                  Продлеваемый договор:{' '}
                  <Link
                    to={`/contract/${quotationData?.full_quotation[0].old_agr_isn}`}
                  >{`«${quotationData?.full_quotation[0].old_agr_id}»`}</Link>
                </Text>
              )}
            </Title>
          </Col>
          {quotationData?.full_quotation[0]?.agr_isn?.length > 0 &&
            (quotationData?.full_quotation[0]?.agr_status === 'Выпущен' ||
              quotationData?.full_quotation[0]?.agr_status === 'Подписан') && (
              <Col xs={4} xl={4}>
                <PrintedForm
                  agrIsn={agrIsn}
                  printAgrIsn={quotationData?.full_quotation[0]?.agr_isn}
                  userForeignId={userForeignId}
                />
              </Col>
            )}

          <Col xs={2} xl={2}>
            <Button
              className="button-flow"
              onClick={() => setOpenNotifications(!openNotification)}
            >
              {!openNotification ? (
                <div className="count-notification">
                  <p className="count-number">
                    {flow?.length !== 0 ? flow?.length : '0'}
                  </p>
                  <img
                    src={notificationBingWhite}
                    alt="notification-bing"
                    className="hover-bell"
                  />
                </div>
              ) : null}
              {!openNotification ? (
                <img
                  src={notificationBingGrey}
                  alt="notification-bing"
                  className="bell-icon"
                />
              ) : (
                'X'
              )}
            </Button>
            {openNotification ? <NotificationsModal flow={flow} /> : null}
          </Col>
          {parseInt(state.agreementData.ISSUED) === 1 && (
            <PrintedForm
              agrIsn={agrIsn}
              userForeignId={userForeignId}
              printAgrIsn={quotationData?.full_quotation[0]?.agr_isn}
            />
          )}
        </Row>

        {step == 1 && (
          <SearchClientModal
            isLoading={isLoading}
            from={from}
            contractorFullInfo={contractorFullInfo}
            isVisible={isVisibleConstactorModal}
            handleCancel={() =>
              contractorFunctions.handelCancelSearchContractorModal(
                dispatch,
                from
              )
            }
            getContractorList={getContractorList}
          />
        )}
        {step == 2 && (
          <ResultClientModal
            isLoading={isLoading}
            contractorList={contractorList}
            handleCancel={() =>
              contractorFunctions.handelCancelSearchContractorModal(
                dispatch,
                from
              )
            }
            selectContractor={selectContractor}
            createContractor={createContractor}
            from={from}
          />
        )}
        {step == 3 && (
          <AddClientModal
            searchContractor={searchContractor}
            isLoading={isLoading}
            isJurUser={isJurUser}
            contractorFullInfo={contractorFullInfo}
            handleCancel={handleCancelAddClientModal}
            selectContractor={updateContractorInfo}
            changeState={changeState}
            selectRelatedContractor={selectRelatedContractor}
            showRelatedInfo={showRelatedInfo}
            relatedInfo={null}
            closeRelatedInfo={closeRelatedInfo}
          />
        )}
        {isRelatedOpen && (
          <AddClientModal
            searchContractor={searchContractor}
            isLoading={isLoading}
            isJurUser={isJurUser}
            contractorFullInfo={null}
            handleCancel={handleCancelAddClientModal}
            selectContractor={selectContractor}
            changeState={changeState}
            selectRelatedContractor={selectRelatedContractor}
            showRelatedInfo={showRelatedInfo}
            relatedInfo={relatedInfo}
            closeRelatedInfo={closeRelatedInfo}
          />
        )}
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            validateForms(forms);
          }}
        >
          <Form name="insurerQuotation" form={insurerForm} layout="vertical">
            <CustomLoader spinning={isLoading}>
              <InsurerQuotation
                agrIsn={agrIsn}
                searchContractor={searchContractor}
                userForeignId={userForeignId}
                contractorInfo={contractorInfo}
                handleRemoveContractorInfo={handleRemoveContractorInfo}
                AdvancedContractorSearch={AdvancedContractorSearch}
                selectContractor={selectContractor}
                productIsn={productIsn}
                old_agr_isn={quotationData?.full_quotation[0].old_agr_isn}
              />
            </CustomLoader>
          </Form>
          <Form
            name="formularQuotation"
            className="c-form"
            form={formularForm}
            layout="vertical"
            onValuesChange={(val) => {
              Object.keys(val).map((key) => {
                if (val[key]) {
                  onFormularChange(val);
                }
              });
            }}
          >
            <FormularQuotation
              agrIsn={agrIsn}
              userForeignId={userForeignId}
              contractorInfo={contractorInfo}
            />
          </Form>

          <Form
            name="membersQuotation"
            form={membersForm}
            layout="vertical"
            className="c-form"
          >
            <MembersQuotation
              agrIsn={agrIsn}
              userForeignId={userForeignId}
              quotationData={quotationData}
            ></MembersQuotation>
          </Form>

          <Form
            name="additionalAttributesForm"
            form={additionalAttributesForm}
            layout="vertical"
            className="c-form"
            scrollToFirstError
          >
            <AdditionalAttributes
              productIsn={productIsn}
              agrIsn={agrIsn}
              userForeignId={userForeignId}
              attributesList={attributesList.map((item: any) =>
                item.sort(
                  (elem1: any, elem2: any) =>
                    Number(elem1.order_no) - Number(elem2.order_no)
                )
              )}
              contractorInfo={contractorInfo}
              objectForm={objectForm}
              isJuridical={isJuridical}
              quotationData={quotationData}
              isShowAttr={isShowAttr}
              setIsShowAttr={setIsShowAttr}
            />
          </Form>

          <Form
            name="documentForm"
            form={documentForm}
            layout="vertical"
            className="c-form"
          >
            <ContractDocument
              agrIsn={agrIsn}
              userForeignId={userForeignId}
              productIsn={productIsn}
              objData={quotationData?.objects}
              condData={quotationData?.conds}
              agreement={quotationData?.docs}
            />
          </Form>

          <Form
            name="clauseQuotationForm"
            form={clauseForm}
            layout="vertical"
            className="c-form"
          >
            <Clauses agrIsn={agrIsn} userForeignId={userForeignId} />
          </Form>

          <Form name="fileQuotationForm" form={fileForm} layout="vertical">
            <FilesQuotation agrIsn={agrIsn} userForeignId={userForeignId} />
          </Form>

          <Form
            name="agrObject"
            form={objectForm}
            layout="vertical"
            initialValues={{ agrObject: [''] }}
            className="c-form"
          >
            {/* {isDynamicProduct ? ( */}
            <ObjectQuotationDynamic
              agrIsn={agrIsn}
              userForeignId={userForeignId}
              productIsn={productIsn}
              objData={quotationData?.objects}
              objAttributesList={{
                additional: objAdditionalAttributesList,
                under: objUnderAttributesList,
              }}
              productSelectedQuotation={productSelectedQuotation}
              getFullData={getFullData}
              agrCond={agrCond}
              objAttrData={quotationData?.attributes_object}
              AdvancedContractorSearch={AdvancedContractorSearch}
              quotationData={quotationData}
            />
            {/* ) : productIsn === 471541 || productIsn === 437401 ? ( */}
            {/* <ObjectQuotation
                agrIsn={agrIsn}
                userForeignId={userForeignId}
                productIsn={productIsn}
                objData={quotationData?.objects}
                objAttributesList={objAttributesList}
                productSelectedQuotation={productSelectedQuotation}
                getFullData={getFullData}
                agrCond={agrCond}
                objAttrData={quotationData.attributes_object}
                AdvancedContractorSearch={AdvancedContractorSearch}
                isUpdate={isUpdate}
              /> */}
            {/* ) : ( */}
            {/* <ObjectQuotationStatic
                agrIsn={agrIsn}
                userForeignId={userForeignId}
                productIsn={productIsn}
                contractorInfo={contractorInfo}
                isJuridical={isJuridical}
              /> */}
            {/* )} */}
          </Form>
          <Form
            name="restQuotation"
            form={form}
            layout="vertical"
            className="c-form"
          >
            {/* <UnderWritingDepartment getQuotationId={getQuotationId()} /> */}
            <MainButtons
              userForeignId={userForeignId}
              agrIsn={agrIsn}
              validateFormsArray={validateFormsArray}
              productIsn={productIsn}
              quotationId={quotationId}
              getFullData={getFullData}
            />
          </Form>
          <Form className="c-form" layout="vertical">
            <StatusQuotation userForeignId={userForeignId} agrIsn={agrIsn} />
          </Form>
        </Form.Provider>
        <Form
          name="requestQuotaion"
          form={requestQuotaionForm}
          layout="vertical"
          initialValues={{ agrObject: [''] }}
        >
          <RequestQuotaion
            userForeignId={userForeignId}
            agrIsn={agrIsn}
            quotationData={quotationData}
            quotationId={quotationId}
            productIsn={productIsn}
          />
        </Form>
      </CustomLoader>
    </div>
  );
});

export default MainQuotation;
