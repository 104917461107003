import React, { memo, useEffect, useState } from 'react';
import { Card, Col, Typography, Space, Dropdown, notification } from 'antd';
import { IMemberQuotation } from '../Interfaces/MembersQuotation/index.interface';
import CButton from '@components/UI/button/CButton';
import CardMembers from '@modules/Quotation/MembersQuotation/CardMembers';
import { IMemberProductItem } from '@modules/Quotation/Interfaces/MainQuotation/index.interface';
import api from '@modules/Quotation/services';
import useQuotation from '@modules/Quotation/hooks/useQuotation';
import useMembersQuotation from '@modules/Quotation/hooks/useMembersQuotation';
import { LoadingOutlined } from '@ant-design/icons';
import SubjectCard from '../ObjectQuotation/ObjectCardDynamic/SubjectCard';

const MembersQuotation: React.FC<IMemberQuotation> = memo(
  ({ agrIsn, userForeignId, quotationData }) => {
    const { Title } = Typography;

    const { agrMembers, setAgrMembers, agrContractor, memberSettings } =
      useQuotation();
    const { members: roles } = useMembersQuotation();

    /**
     * Отправляем запрос на добавление участника
     * @param role - isn роли
     * @param isn - isn участника
     */
    const sendMembersQuotation = async ({ role, isn }) => {
      const sendData = {
        agr_isn: agrIsn,
        connection: 'centras',
        role_isn: role,
        subj_isn: isn,
        user_isn: userForeignId,
      };
      const { data } = await api.contractor.saveContractor(sendData);

      if (data.success === true) {
        notification.success({
          message: 'Сообщение',
          description: data.message,
        });
        return true;
      } else {
        notification.info({
          message: 'Ошибка',
          description: data.error,
        });
        return false;
      }
    };

    /**
     * Удаляет участника
     * @param member - объект участника
     * @param deleteIndex - индекс участника в списке
     */
    const deleteMembers = async (member, deleteIndex) => {
      if (!deleteIndex && deleteIndex !== 0) return;
      const { foreign_id: role, ISN: isn, subj_isn, class_isn } = member;
      if (!(isn || subj_isn)) {
        setAgrMembers((item) =>
          item.filter((element, elementIndex) => elementIndex !== deleteIndex)
        );
      } else {
        try {
          const sendData = {
            agr_isn: agrIsn,
            class_isn: role || class_isn,
            subj_isn: isn || subj_isn,
            user_isn: userForeignId,
          };
          const { data } = await api.contractor.deleteContractor(sendData);

          setAgrMembers((item) =>
            item.filter((element, elementIndex) => elementIndex !== deleteIndex)
          );
          notification.success({
            message: 'Участник успешно удален',
          });
          return data;
        } catch (error) {
          console.log(error);

          let message;
          message = error || 'Непредвиденная ошибка';
          notification.error({
            message: 'Ошибка',
            description: message,
          });
          return false;
        }
      }
    };

    const updateMembers = async (newMember) => {
      const data = await sendMembersQuotation({
        role: newMember.foreign_id,
        isn: newMember.ISN,
      });

      if (!data) return;
      const temp: Array<any> = [...agrMembers];
      const existingElementIndex = temp.findIndex(
        (item: IMemberProductItem) => item.foreign_id === newMember.foreign_id
      );
      if (existingElementIndex !== -1) {
        // Если элемент с a.id = b.id уже существует, обновляем его
        temp[existingElementIndex] = {
          ...temp[existingElementIndex],
          ...newMember,
        };
      } else {
        // Если элемента с a.id = b.id нет, добавляем элемент b в массив a
        temp.push(newMember);
      }
      if (temp.length) {
        setAgrMembers(temp);
      }
    };

    /**
     * Добавляет поле для добавления нового участг=ника
     */
    const handleMemberAdd = () => {
      setAgrMembers([
        ...agrMembers,
        {
          active_mycent: true,
        },
      ]);
    };
    useEffect(() => {
      if (memberSettings?.length > 0) {
        if (quotationData?.members.length) {
          setAgrMembers([
            ...quotationData.members,
            ...memberSettings.filter(
              (set) =>
                quotationData.members.filter(
                  (member) =>
                    Number(set.foreign_id) === Number(member.class_isn)
                ).length === 0
            ),
          ]);
        } else {
          setAgrMembers(memberSettings);
        }
      } else if (quotationData?.members.length) {
        setAgrMembers(quotationData?.members);
      }
    }, [quotationData, memberSettings]);
    useEffect(() => {
      if (agrContractor) {
        if (agrMembers.length > 0) {
          setAgrMembers(
            agrMembers.map((member) => {
              if (
                Number(member.foreign_id) === 2103 ||
                Number(member.class_isn) === 2103
              ) {
                return {
                  ...member,
                  agr_isn: agrIsn,
                  bonus_malus_isn: null,
                  class_isn: '2103',
                  isn: null,
                  order_no: null,
                  remark: null,
                  subj_birthday: agrContractor.client_birthday,
                  subj_full_name: agrContractor.client_name,
                  subj_iin: agrContractor.client_iin,
                  subj_isn: agrContractor.client_id,
                  user_isn: agrContractor.client_id,
                };
              } else {
                return member;
              }
            })
          );
        } else {
          if (memberSettings) {
            setAgrMembers(
              memberSettings.map((member) => {
                if (member.class_isn === '2103') {
                  return {
                    ...member,
                    agr_isn: agrIsn,
                    bonus_malus_isn: null,
                    class_isn: '2103',
                    isn: null,
                    order_no: null,
                    remark: null,
                    subj_birthday: agrContractor.client_birthday,
                    subj_full_name: agrContractor.client_name,
                    subj_iin: agrContractor.client_iin,
                    subj_isn: agrContractor.client_id,
                    user_isn: agrContractor.client_id,
                  };
                } else {
                  return member;
                }
              })
            );
          } else {
            setAgrMembers([
              {
                agr_isn: agrIsn,
                bonus_malus_isn: null,
                class_isn: '2103',
                isn: null,
                order_no: null,
                remark: null,
                subj_birthday: agrContractor.client_birthday,
                subj_full_name: agrContractor.client_name,
                subj_iin: agrContractor.client_iin,
                subj_isn: agrContractor.client_id,
                user_isn: agrContractor.client_id,
              },
            ]);
          }
        }
      }
    }, [agrContractor]);
    return (
      <Card style={{ minHeight: 200 }} className="mt-5">
        <Col xs={24} xl={8}>
          <Title className="main-quotation__section-title" level={3}>
            Участники
          </Title>
        </Col>
        {agrMembers.map(
          (item, index) =>
            (item.active_mycent || item.user_isn) && (
              <CardMembers
                member={item}
                index={index}
                updateMembers={updateMembers}
                deleteMembers={deleteMembers}
                key={index}
                roles={roles}
                agr_isn={agrIsn}
              />
            )
        )}
        {agrMembers.length ===
        agrMembers.filter((member) => member.foreign_id || member.subj_iin)
          .length ? (
          <div
            className="mt-6"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <CButton
              type="text"
              size="large"
              style={{
                color: '#7449ef',
                fontWeight: 600,
              }}
              onClick={handleMemberAdd}
              disabled={
                Number(quotationData?.full_quotation[0].status_id) !== 223368
              }
            >
              + Добавить участника
            </CButton>
          </div>
        ) : null}
      </Card>
    );
  }
);
export default MembersQuotation;
