import { useState } from 'react';
import './ObjectQuotation.scss';
import { SearchOutlined } from '@ant-design/icons';
import {
  Form,
  Card,
  Col,
  Row,
  Typography,
  notification,
  Select,
  Button,
} from 'antd';
import ObjectQuotationProps from './type';
import { useImmerReducer } from 'use-immer';
import CInput from '@components/UI/input/CInput';
import CButton from '@components/UI/button/CButton';
import ObjectQuotationItemDynamic from './ObjectCar/ObjectQuotationItemDynamic';
import SearchCarModal from './ObjectCar/SearchCarModal';
import ResultCarModal from './ObjectCar/ResultCarModal';
import CardCarModalDynamic from './ObjectCar/CardCarModalDynamic';
import api from '@modules/Quotation/services';
import axios from 'axios';
import dayjs from 'dayjs';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
import useQuotation from '@modules/Quotation/hooks/useQuotation';
import CSelect from '@components/UI/select/CSelect';
import ObjectCardModalDynamic from './ObjectCardDynamic/ObjectCardModalDynamic';

const { Option } = Select;

const { Title } = Typography;

type Action = {
  type:
    | 'nextStep'
    | 'backStep'
    | 'saveAutoList'
    | 'loading'
    | 'selectCar'
    | 'modalSettings'
    | 'closeModal'
    | 'setDicti'
    | 'selectObject';
  payload?: any;
};

interface State {
  isOpenTransport: boolean;
  isOpenModal: boolean;
  selectedCar: any;
  autoList: any[];
  step: number;
  isLoading: boolean;
  modalSetting: any;
  selectedObj: any;
  dicti: any;
}
const initialState: State = {
  isOpenTransport: false,
  isOpenModal: false,
  selectedCar: null,
  autoList: [],
  step: 0,
  isLoading: false,
  modalSetting: null,
  selectedObj: null,
  dicti: null,
};
const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'saveAutoList': {
      draft.autoList = action.payload;
      break;
    }
    case 'closeModal': {
      if (action.payload?.type === 'car') {
        draft.isOpenTransport = false;
        draft.step = 0;
        draft.modalSetting = null;
      } else {
        draft.isOpenModal = false;
        draft.modalSetting = null;
      }
      break;
    }
    case 'selectCar': {
      draft.selectedCar = action.payload;
      break;
    }
    case 'selectObject': {
      draft.selectedObj = action.payload;
      break;
    }
    case 'loading': {
      draft.isLoading = action.payload;
      break;
    }
    case 'modalSettings': {
      draft.modalSetting = {
        item: action.payload.item,
        primary_cond: action.payload.primary_cond,
      };
      break;
    }
    case 'nextStep': {
      draft.step += 1;
      if (action.payload?.type === 'car') {
        draft.isOpenTransport = true;
      }
      if (action.payload?.type === 'any') {
        draft.isOpenModal = true;
      }
      if (action.payload?.step) {
        draft.step = action.payload.step;
      }
      break;
    }
    case 'backStep': {
      if (action.payload?.type === 'car') {
        draft.isOpenTransport = false;
        draft.step -= 1;
      }
      break;
    }
    case 'setDicti': {
      if (draft.dicti) {
        draft.dicti = { ...draft.dicti, ...action.payload };
      } else {
        draft.dicti = action.payload;
      }
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

const ObjectQuotationDynamic = ({
  agrIsn,
  userForeignId,
  productIsn,
  objData,
  objAttributesList,
  productSelectedQuotation,
  getFullData,
  agrCond,
  objAttrData,
  AdvancedContractorSearch,
  quotationData,
}) => {
  const [transportForm] = Form.useForm();
  const [attrObjForm] = Form.useForm();
  const [carForm] = Form.useForm();
  const [mainForm] = Form.useForm();
  const [objectCreateForm] = Form.useForm();
  const [primaryCondForm] = Form.useForm();
  const [additionalObjAttributesForm] = Form.useForm();
  const [additionalUnderForm] = Form.useForm();
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const {
    isOpenTransport,
    step,
    autoList,
    isLoading,
    selectedCar,
    modalSetting,
    isOpenModal,
    selectedObj,
    dicti,
  } = state;

  const [objects, setObjects] = useState<any>();
  const { stateDateQuotation } = useQuotation();
  const saveObjectCar = async (data) => {
    dispatch({ type: 'loading', payload: true });
    try {
      const carInfo = await api.saveQuotation.carSave({
        agr_isn: `${agrIsn}`,
        auto_bodyid: data.BODYID,
        auto_categoryisn: +data.CATEGORYISN,
        auto_colorisn: +data.COLORISN,
        auto_modelisn: data.MODELISN,
        auto_power: data.POWER,
        auto_ptsclassisn: data.auto_ptsclassisn,
        auto_ptsdate: dayjs(data.DOCDATE).format('YYYY-MM-DD'),
        auto_ptsno: data.DOCNO,
        auto_ptsser: data.DOCSER,
        auto_regno: data.REGNO,
        auto_vin: data.VIN,
        auto_volume: data.VOLUME,
        fid_obj: data.fid_obj,
        obj_isn: data.obj_isn,
        sub_class_isn: +data.sub_class_isn,
        auto_markisn: data.BRAND,
        auto_mark: data.BRAND,
        auto_engineid: data.ENGINEID,
        auto_countryisn: data.COUNTRYISN,
        auto_year: data.RELEASEDATE
          ? dayjs(data.RELEASEDATE).format('YYYY-MM-DD')
          : null,
        auto_probeg: data.PROBEG,
        auto_chassisid: data.CHASSISID,
        auto_ownerisn: data.OWNERISN,
        num_seats: data.NUMSEATS,
        auto_modification: data.MODIFICATION,
        auto_caruseisn: data.CARUSEISN,
        auto_ownershipisn: data.OWNERSHIPISN,
        auto_realprice: data.REALPRICE,
        auto_rightwheel: data.RIGHTWHEEL,
        tonnage: data.TONNAGE,
        max_mass: data.MAXMASS,
        num_key: data.NUMKEY,
        multi_drive: data.MULTIDRIVE,
        foreign: data.FOREIGN,
        auto_territoryisn: data.TERRITORYISN,
        date_beg: data.DATEBEG
          ? dayjs(data.DATEBEG).format('YYYY-MM-DD')
          : null,
        passenger: data.PASSENGER,
        use_trailer: data.USETRAILER,
        use_special_signal: data.USESPECIALSIGNAL,
        updated_by: userForeignId,
        territory_guid: data.auto_territoryguid,
      });
      if (carInfo.data.success) {
        const all = await api.saveQuotation.getCarObjByIsn(data.obj_isn);
        dispatch({
          type: 'selectCar',
          payload: all.data.data[0],
        });
        dispatch({ type: 'loading', payload: false });
        notification.success({
          message: 'Успешно',
          description: 'Данные успешно сохранены',
        });
      } else {
        dispatch({ type: 'loading', payload: false });
      }
    } catch (error) {
      console.log(error);

      dispatch({ type: 'loading', payload: false });
      let message;

      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: 'Ошибка',
        description: message,
      });
    }
  };
  const saveObjectCond = async (risk, setRisk) => {
    try {
      const formData = primaryCondForm.getFieldsValue();

      Object.keys(formData).map(async (key) => {
        if (risk) {
          Object.keys(risk).map(async (risk_key) => {
            if (key === risk_key) {
              let max = 0;
              if (agrCond) {
                agrCond.map((item) => {
                  if (Number(item.fid) > max) {
                    max = Number(item.fid);
                  }
                });
              }
              Object.keys(formData).map((key) => {
                if (formData[key].fid > max) {
                  max = Number(formData[key].fid);
                }
              });

              const info = await api.saveQuotation.saveObjCond({
                agr_cond: [
                  {
                    agr_isn: `${agrIsn}`,
                    obj_isn: formData[key].obj_isn,
                    isn: formData[key].cond_isn,
                    fid: formData[key].fid
                      ? formData[key].fid == 0
                        ? 1
                        : formData[key].fid
                      : max + 1,
                    cond_name: '',
                    cond_isn: formData[key].cond_isn,
                    risk_isn: formData[key].risk_isn,
                    risk_name: productSelectedQuotation[0].risks?.filter(
                      (item) => item.risk_isn === formData[key].risk_isn
                    )[0]?.risk_name,
                    isn_class: formData[key].ins_type_isn,
                    // isn_class: 1,
                    addendum_sum: 0,
                    date_sign: formData[key].date_sign
                      ? dayjs(formData[key].date_sign).format('YYYY-MM-DD') +
                        ' 00:00:00'
                      : dayjs(quotationData.full_quotation[0].date_sign).format(
                          'YYYY-MM-DD'
                        ) + ' 00:00:00',
                    date_beg: formData[key].date_beg
                      ? dayjs(formData[key].date_beg).format('YYYY-MM-DD') +
                        ' 00:00:00'
                      : null,
                    date_end: formData[key].date_end
                      ? dayjs(formData[key].date_end).format('YYYY-MM-DD') +
                        ' 00:00:00'
                      : null,
                    duration: null,
                    curr_isn: 9813,
                    curr_sum_isn: 9813,
                    limit_sum: formData[key].insurer_sum,
                    tariff: formData[key].tariff,
                    // tariff_str: formData[key].tariff
                    //   ? `${formData[key].tariff}`
                    //   : '',
                    premium_sum: formData[key].premium_sum,
                    franch_type: formData[key].franch_type,
                    franch_tariff: formData[key].franch_tariff,
                    franch_sum: formData[key].franch_sum,
                    fid_obj: formData[key].fid_obj,
                    user_isn: userForeignId,
                    remark: formData[key].remark,
                    limitsum_type: formData[key].type,
                    limitsum_ext: formData[key].limit,
                    comission_proc: formData[key].commission,
                    comission_sum: formData[key].commission_sum,
                    discount: formData[key].discount
                      ? formData[key].discount
                      : '',
                    operation: formData[key].operation,
                  },
                ],
              });
              if (info.data.success) {
                info.data.data.map((item, index) => {
                  primaryCondForm.setFieldsValue({
                    [index]: {
                      type: item.LIMITSUMTYPE,
                      date_end: dayjs(item.DATEEND),
                      date_sign: dayjs(item.DATESIGN),
                      date_beg: dayjs(item.DATEBEG),
                      curr_isn: Number(item.CURRISN),
                      curr_isn_calc: Number(item.CURRSUMISN),
                      franch_type: item.FRANCHTYPE,
                      cond_isn: item.ISN,
                      fid: item.FID,
                      remark: item.REMARK,
                    },
                  });
                });
              }
              if (risk[risk_key]) {
                const { data } = await api.quotation.saveQuotationRisk({
                  user_isn: userForeignId,
                  agrrisk: risk[risk_key].map((item) => {
                    return {
                      isn: item.isn,
                      agrisn: `${agrIsn}`,
                      objisn: info.data.data.filter((condItem) =>
                        formData[key].fid
                          ? Number(condItem.FID) === Number(formData[key].fid)
                          : Number(condItem.FID) === Number(max) + 1
                      )[0].OBJISN,
                      damageclassisn: item.damageclassisn,
                      franchtariff: item.franchtariff,
                      franchsum: item.franchsum,
                      franchtype: item.franchtype,
                      remark: item.franch_remark,
                      condisn: info.data.data.filter((condItem) =>
                        formData[key].fid
                          ? Number(condItem.FID) === Number(formData[key].fid)
                          : Number(condItem.FID) === Number(max) + 1
                      )[0].ISN,
                      riskisn: item.risk_isn,
                      operation:
                        formData[key].operation === 'D' ? 'D' : item.operation,
                    };
                  }),
                });
                setRisk({
                  ...risk,
                  [risk_key]: data.data.map((item) => {
                    return {
                      risk_isn: item.RISKISN,
                      isn: item.ISN,
                      objisn: item.OBJISN,
                      condisn: item.CONDISN,
                      foreign_id: item.ISN,
                      risk_name: item.RISKISN_NAME,
                      franchtype: item.FRANCHTYPE,
                      franchtariff: item.FRANCHTARIFF,
                      franchsum: item.FRANCHSUM,
                      franchtext: item.FRANCHEXT,
                      franch_percent: item.FRANCHTARIFF,
                      loss_name: item.DAMAGECLASSISN_NAME,
                      remark: item.REMARK,
                      franch_remark: item.REMARK,
                    };
                  }),
                });
              }
            }
          });
        } else {
          let max = 0;
          if (agrCond) {
            agrCond.map((item) => {
              if (Number(item.fid) > max) {
                max = Number(item.fid);
              }
            });
          }
          Object.keys(formData).map((key) => {
            if (formData[key].fid > max) {
              max = Number(formData[key].fid);
            }
          });

          const info = await api.saveQuotation.saveObjCond({
            agr_cond: [
              {
                agr_isn: `${agrIsn}`,
                obj_isn: formData[key].obj_isn,
                isn: formData[key].cond_isn,
                fid: formData[key].fid
                  ? formData[key].fid == 0
                    ? 1
                    : formData[key].fid
                  : max + 1,
                cond_name: '',
                cond_isn: formData[key].cond_isn,
                risk_isn: formData[key].risk_isn,
                risk_name: productSelectedQuotation[0].risks?.filter(
                  (item) => item.risk_isn === formData[key].risk_isn
                )[0]?.risk_name,
                isn_class: formData[key].ins_type_isn,
                // isn_class: 1,
                addendum_sum: 0,
                date_sign: formData[key].date_sign
                  ? dayjs(formData[key].date_sign).format('YYYY-MM-DD') +
                    ' 00:00:00'
                  : dayjs(quotationData.full_quotation[0].date_sign).format(
                      'YYYY-MM-DD'
                    ) + ' 00:00:00',
                date_beg: formData[key].date_beg
                  ? dayjs(formData[key].date_beg).format('YYYY-MM-DD') +
                    ' 00:00:00'
                  : null,
                date_end: formData[key].date_end
                  ? dayjs(formData[key].date_end).format('YYYY-MM-DD') +
                    ' 00:00:00'
                  : null,
                duration: null,
                curr_isn: 9813,
                curr_sum_isn: 9813,
                limit_sum: formData[key].insurer_sum,
                tariff: formData[key].tariff,
                // tariff_str: formData[key].tariff
                //   ? `${formData[key].tariff}`
                //   : '',
                premium_sum: formData[key].premium_sum,
                franch_type: formData[key].franch_type,
                franch_tariff: formData[key].franch_tariff,
                franch_sum: formData[key].franch_sum,
                fid_obj: formData[key].fid_obj,
                user_isn: userForeignId,
                remark: formData[key].remark,
                limitsum_type: formData[key].type,
                limitsum_ext: formData[key].limit,
                comission_proc: formData[key].commission,
                comission_sum: formData[key].commission_sum,
                discount: formData[key].discount ? formData[key].discount : '',
                operation: formData[key].operation,
              },
            ],
          });
          if (info.data.success) {
            info.data.data.map((item, index) => {
              primaryCondForm.setFieldsValue({
                [index]: {
                  type: item.LIMITSUMTYPE,
                  date_end: dayjs(item.DATEEND),
                  date_sign: dayjs(item.DATESIGN),
                  date_beg: dayjs(item.DATEBEG),
                  curr_isn: Number(item.CURRISN),
                  curr_isn_calc: Number(item.CURRSUMISN),
                  franch_type: item.FRANCHTYPE,
                  cond_isn: item.ISN,
                  fid: item.FID,
                  remark: item.REMARK,
                },
              });
            });
          }
        }
      });
      // console.log();

      notification.success({
        message: 'Данные успешно сохранены',
      });
    } catch (error) {
      let message;

      console.log(error);

      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: 'Ошибка',
        description: message,
      });
    }

    // });
  };
  /*
    установка поле defaultKiasValues
    нужно чтобы не отправлять через пропсы данные, которые приходят при заходе в котировку
    а их просто можно вытащить на любом уровне вложенности
    */

  // useEffect(() => {
  //   if (agrIsn === undefined && userForeignId === undefined) return;
  //   objectForm.setFieldsValue({
  //     defaultKiasValues: `agrIsn/${agrIsn}/userForeignId/${userForeignId}`,
  //   });
  // }, [agrIsn, userForeignId]);

  const openObjectModal = async (props) => {
    if (productSelectedQuotation[0].objects_attr) {
      let dictiItem;
      productSelectedQuotation[0].objects_attr.map((attr) => {
        attr.filter(async (item) => {
          if (item.attr_type === 'DICTI' || item.attr_type === 'SQL') {
            const { data } = await api.auto.getDictiByIsn({
              isn: item.attr_isn,
              obj_isn: agrIsn,
            });
            if (dictiItem) {
              dictiItem = {
                ...dictiItem,
                [item.attr_isn]: data.data,
              };
            } else {
              dictiItem = { [item.attr_isn]: data.data };
            }
          }
          dispatch({
            type: 'setDicti',
            payload: dictiItem,
          });
        });
        dispatch({ type: 'loading', payload: false });
      });
    }
    if (props.item.card) {
      let item;
      Object.keys(props?.item?.card).filter(async (key) => {
        if (props?.item?.card[key] && props?.item?.card[key].code === 'DICTI') {
          const { data } = await api.auto.getDictiByIsn({
            isn: props?.item?.card[key].dicti_isn,
            obj_isn: agrIsn,
          });
          if (item) {
            item = { ...item, [props?.item?.card[key].dicti_isn]: data.data };
          } else {
            item = { [props?.item?.card[key].dicti_isn]: data.data };
          }
        }
        dispatch({
          type: 'setDicti',
          payload: item,
        });
      });
      dispatch({ type: 'loading', payload: false });
    }
    if (props.item.isn === 2118) {
      dispatch({
        type: 'nextStep',
        payload: { type: 'car', step: props.step },
      });
      dispatch({
        type: 'modalSettings',
        payload: {
          item: props.item,
          primary_cond: productSelectedQuotation[0].primary_conditions,
        },
      });

      dispatch({ type: 'selectCar', payload: props.obj });
    } else {
      dispatch({
        type: 'nextStep',
        payload: { type: 'any', step: props.step },
      });
      dispatch({
        type: 'modalSettings',
        payload: {
          item: props.item,
          primary_cond: productSelectedQuotation[0].primary_conditions,
        },
      });
      if (props.item.card.length) {
        if (props?.obj?.isn) {
          const all = await api.saveQuotation.getCarObjByIsn(props.obj.isn);
          dispatch({
            type: 'selectObject',
            payload: all.data.data[0],
          });
        }
      } else {
        dispatch({ type: 'selectObject', payload: props.obj });
      }
    }
  };
  const saveObject = async () => {
    try {
      let max = 0;
      if (objData) {
        Object.keys(objData).map((key) => {
          objData[key]?.map((item) => {
            if (item.fid > max) {
              max = item.fid;
            }
          });
        });
      }

      const { data } = await api.saveQuotation.objSave({
        agr_obj: [
          {
            ...mainForm.getFieldsValue(),
            agr_isn: agrIsn,
            class_isn: mainForm.getFieldsValue().class_isn,
            sub_class_isn: mainForm.getFieldsValue().sub_class_isn,
            obj_name: mainForm.getFieldsValue().obj_name,
            obj_count: 1,
            address: mainForm.getFieldsValue().address,
            cityisn: mainForm.getFieldsValue().cityisn,
            subj_isn: mainForm.getFieldsValue().human_isn,
            // ext_system_key: data.ID,
            user_isn: userForeignId,
            fid: selectedObj ? selectedObj.fid_obj : max + 1,
            // fid_obj: selectedObj.fid,
          },
        ],
      });
      if (data.success) {
        if (modalSetting.item.card.length) {
          let sendData = {};
          Object.keys(mainForm.getFieldsValue()).map((key) => {
            if (typeof mainForm.getFieldsValue()[key] === 'object') {
              sendData = {
                ...sendData,
                [key.toLowerCase()]: mainForm.getFieldsValue()[key]
                  ? dayjs(mainForm.getFieldsValue()[key]).format('DD.MM.YYYY')
                  : null,
              };
            } else if (
              mainForm.getFieldsValue()[key] === 'Y' ||
              mainForm.getFieldsValue()[key] === 'N'
            ) {
              sendData = {
                ...sendData,
                [key.toLowerCase()]: mainForm.getFieldsValue()[key] ? 'Y' : 'N',
              };
            } else {
              sendData = {
                ...sendData,
                [key.toLowerCase()]: mainForm.getFieldsValue()[key],
              };
            }
          });

          const info = await api.saveQuotation.saveCard({
            ...sendData,
            userisn: userForeignId,
            isn: Number(data.data.ISN),
          });
          if (info.data.success) {
            const all = await api.saveQuotation.getCarObjByIsn(data.data.ISN);
            dispatch({
              type: 'selectObject',
              payload: all.data.data[0],
            });
            notification.success({
              message: 'Данные успешно сохранены',
            });
          }
        } else {
          notification.success({
            message: 'Данные успешно сохранены',
          });
          dispatch({
            type: 'selectObject',
            payload: {
              agr_isn: agrIsn,
              class_isn: data.data?.CLASSISN,
              extsystemkey: data.data.EXTSYSTEMKEY,
              fid: data.data.FID,
              fid_obj: data.data.FID,
              isn: data.data.ISN,
              obj_count: data.data.OBJCOUNT,
              obj_name: data.data.OBJNAME,
              order_no: data.data.ORDERNO,
              parent_isn: data.data.PARENTISN,
              remark: data.data.REMARK,
              sub_class_isn: data.data.SUBCLASSISN,
              subj_isn: data.data.SUBJISN,
              address: mainForm.getFieldsValue().address,
              cityisn: mainForm.getFieldsValue().cityisn,
            },
          });
        }
      } else {
        notification.info({
          message: 'Ошибка',
          description: data.error,
        });
      }
    } catch (error) {
      console.log(error);

      let message;

      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: 'Ошибка',
        description: message,
      });
    }
  };
  const closeTransportModal = () => {
    transportForm.resetFields();
    attrObjForm.resetFields();
    carForm.resetFields();
    primaryCondForm.resetFields();
    additionalObjAttributesForm.resetFields();
    additionalUnderForm.resetFields();

    getFullData();
    dispatch({ type: 'closeModal', payload: { type: 'car' } });
  };
  const closeObjectModal = () => {
    mainForm.resetFields();
    attrObjForm.resetFields();
    carForm.resetFields();
    primaryCondForm.resetFields();
    additionalObjAttributesForm.resetFields();
    additionalUnderForm.resetFields();
    getFullData();
    dispatch({ type: 'selectObject', payload: null });
    dispatch({ type: 'closeModal', payload: { type: 'car' } });
  };
  const searchTransportList = async (info) => {
    try {
      dispatch({ type: 'loading', payload: true });
      const { data } = await api.auto.searchCar({
        ...transportForm.getFieldsValue(),
        user_isn: userForeignId,
      });
      dispatch({ type: 'saveAutoList', payload: data.data });
      dispatch({ type: 'loading', payload: false });
      dispatch({ type: 'nextStep' });
    } catch (error) {
      console.log(error);

      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }

      dispatch({ type: 'loading', payload: false });
      notification.error({
        message: 'Ошибка',
        description: message,
      });
    }
  };
  const selectCar = async (data, item) => {
    dispatch({ type: 'loading', payload: true });
    if (item) {
      dispatch({ type: 'modalSettings', payload: { item } });
    }
    if (!data.MARK) {
      const carInfo = await api.saveQuotation.getCarObjByIsn(data.isn);
      dispatch({
        type: 'selectCar',
        payload: carInfo.data.data[0],
      });
    } else {
      const carInfo = await api.auto.searchCar({
        reg_no: data.REG_NUM,
        vin: data.VIN,
        user_isn: userForeignId,
      });
      let max = 0;
      if (objData) {
        Object.keys(objData).map((key) => {
          objData[key].map((item) => {
            if (item.fid > max) {
              max = item.fid;
            }
          });
        });
      }
      const info = await api.saveQuotation.objSave({
        agr_obj: [
          {
            agr_isn: agrIsn,
            class_isn: 2118,
            sub_class_isn: carInfo.data.data.TYPE_ID_KIAS,
            obj_name: `${data.MARK} ${data.MODEL} <${data.REG_NUM}>`,
            obj_count: 1,
            extsystemkey: data.ID,
            user_isn: userForeignId,
            fid: max + 1,
          },
        ],
      });
      await api.saveQuotation.carSave({
        agr_isn: `${agrIsn}`,
        fid_obj: +info.data.data.FID,
        auto_modelisn: data.MODEL_ID_KIAS,
        auto_year: `${data.NYEAR}-01-01`,
        auto_markisn: data.MARK_ID_KIAS,
        auto_ptsser: data.REG_CERT_NUM
          ? data.REG_CERT_NUM[0] + data.REG_CERT_NUM[1]
          : null,
        auto_ptsno: data.REG_CERT_NUM
          ? data.REG_CERT_NUM.split(data.REG_CERT_NUM[1])[1]
          : null,
        auto_ptsdate: data.DT_REG_CERT,
        doc_ser: data.REG_CERT_NUM
          ? data.REG_CERT_NUM[0] + data.REG_CERT_NUM[1]
          : null,
        doc_no: data.REG_CERT_NUM?.split(data.REG_CERT_NUM[1])[1],
        doc_date: data.DT_REG_CERT,
        auto_vin: data.VIN,
        auto_regno: data.REG_NUM,
        auto_bodyid: data.BODYNUM,
        auto_volume: data.ENGINE_VOLUME,
        num_seats: data.NPLACES,
        auto_colorisn: data.COLORISN,
        auto_power: data.ENGINE_POWER,
        auto_categoryisn: 10830,
        right_wheel: data.RIGHT_HAND_DRIVE_BOOL,
        territory_guid: data.BIG_CITY_BOOL,
        auto_countryisn: data.COUNTRYISN,
        extsystemkey: data.ID,
        updated_by: userForeignId,
        auto_ptsclassisn: 225346,
      });
      const all = await api.saveQuotation.getCarObjByIsn(info.data.data.ISN);
      dispatch({
        type: 'selectCar',
        payload: all.data.data[0],
      });
    }
    dispatch({ type: 'nextStep', payload: { step: 3, type: 'car' } });
    dispatch({ type: 'loading', payload: false });
  };
  const deleteObj = async (data) => {
    dispatch({ type: 'loading', payload: true });
    await api.saveQuotation.deleteObj({
      agr_isn: `${data.agr_isn}`,
      fid: data.fid,
      user_isn: userForeignId,
    });
    getFullData();
    setTimeout(() => {
      dispatch({ type: 'loading', payload: false });
    }, 500);
  };

  const onFinishObjectForm = () => {
    if (objectCreateForm.getFieldsValue().object_type) {
      if (objects?.length > 0) {
        productSelectedQuotation[0].objects.filter((item) => {
          if (
            item.foreign_id === objectCreateForm.getFieldsValue().object_type
          ) {
            setObjects([...objects, item]);
          }
        });
      } else {
        productSelectedQuotation[0].objects.filter((item) => {
          if (
            item.foreign_id === objectCreateForm.getFieldsValue().object_type
          ) {
            setObjects([item]);
          }
        });
      }
    } else {
      notification.error({
        message: 'Заполните все поля',
      });
    }
  };
  return (
    <Card className="quotation-objects mt-5">
      <Row>
        <Col sm={24} md={8}>
          <Title className="main-quotation__section-title" level={3}>
            Объекты
          </Title>
        </Col>

        <Col sm={24} md={16}>
          <Row gutter={12}>
            <Col sm={24} md={12}>
              <CInput
                width={'100%'}
                placeholder="Поиск по ключевым словам"
                prefix={<SearchOutlined />}
                size="large"
              />
            </Col>
            <Col sm={24} md={12}>
              <CButton className="c-button_left w-100" type="primary">
                <SearchOutlined />
                Поиск по атрибутам
              </CButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form className="c-form" layout="vertical" form={objectCreateForm}>
        <Row gutter={6}>
          <Col span={16} className="mt-5">
            <Form.Item label="Класс объекта" name="object_type" required={true}>
              <CSelect
                placeholder="Класс объекта"
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                {productSelectedQuotation[0] &&
                  productSelectedQuotation[0].objects?.map((item, index) => (
                    <Option value={item.foreign_id} key={item.foreign_id}>
                      {item.full_name}
                    </Option>
                  ))}
              </CSelect>
            </Form.Item>
          </Col>
          <Col span={8} className="mt-5">
            <Form.Item>
              <Button
                type="primary"
                style={{ width: '100%' }}
                onClick={onFinishObjectForm}
              >
                Создать объект
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {productSelectedQuotation[0] &&
        productSelectedQuotation[0].objects?.map((item) => {
          if (objData && objData[item.isn]) {
            return (
              <div key={`${item.isn}`}>
                <span
                  style={{
                    fontSize: 24,
                    display: 'block',
                    margin: '15px 0',
                  }}
                >
                  {item.full_name}
                </span>
                <Row gutter={18} wrap={true}>
                  {objData[item.isn]?.map((obj, index) => {
                    return (
                      <Col span={8} key={obj.isn} className="mb-3">
                        <ObjectQuotationItemDynamic
                          props={{
                            obj,
                            openObjectModal,
                            deleteObj,
                            agrCond,
                            item,
                          }}
                        />
                      </Col>
                    );
                  })}
                  <Col span={8}>
                    <Card
                      className="quotation-object quotation-object_add-new"
                      onClick={
                        checkDisabledField(stateDateQuotation.status_isn)
                          ? () => {}
                          : () =>
                              openObjectModal({
                                item,
                                step: item.isn === 2118 ? 1 : 3,
                              })
                      }
                    >
                      + Добавить {item.full_name}
                    </Card>
                  </Col>
                </Row>
              </div>
            );
          }
        })}
      {productSelectedQuotation[0]?.objects?.length > 1
        ? objects?.map((item) => {
            if (!objData || !objData[item.isn]) {
              return (
                <>
                  <span
                    style={{
                      fontSize: 24,
                      display: 'block',
                      margin: '15px 0',
                    }}
                  >
                    {item.full_name}
                  </span>
                  <Row gutter={18} wrap={true}>
                    {objData &&
                      objData[item.isn]?.map((obj, index) => {
                        return (
                          <Col span={8} key={index} className="mb-3">
                            <ObjectQuotationItemDynamic
                              props={{
                                obj,
                                openObjectModal,
                                deleteObj,
                                agrCond,
                                item,
                              }}
                            />
                          </Col>
                        );
                      })}
                    <Col span={8}>
                      <Card
                        className="quotation-object quotation-object_add-new"
                        onClick={
                          checkDisabledField(stateDateQuotation.status_isn)
                            ? () => {}
                            : () => {
                                openObjectModal({
                                  item,
                                  step: item.isn === 2118 ? 1 : 3,
                                });
                              }
                        }
                      >
                        + Добавить {item.full_name}
                      </Card>
                    </Col>
                  </Row>
                </>
              );
            }
          })
        : productSelectedQuotation[0]?.objects?.map((item) => {
            if (!objData || !objData[item.isn]) {
              return (
                <>
                  <span
                    style={{
                      fontSize: 24,
                      display: 'block',
                      margin: '15px 0',
                    }}
                  >
                    {item.full_name}
                  </span>
                  <Row gutter={18} wrap={true}>
                    {objData &&
                      objData[item.isn]?.map((obj, index) => {
                        return (
                          <Col span={8} key={index} className="mb-3">
                            <ObjectQuotationItemDynamic
                              props={{
                                obj,
                                openObjectModal,
                                deleteObj,
                                agrCond,
                                item,
                              }}
                            />
                          </Col>
                        );
                      })}
                    <Col span={8}>
                      <Card
                        className="quotation-object quotation-object_add-new"
                        onClick={
                          checkDisabledField(stateDateQuotation.status_isn)
                            ? () => {}
                            : () => {
                                openObjectModal({
                                  item,
                                  step: item.isn === 2118 ? 1 : 3,
                                });
                              }
                        }
                      >
                        + Добавить {item.full_name}
                      </Card>
                    </Col>
                  </Row>
                </>
              );
            }
          })}
      <SearchCarModal
        form={transportForm}
        closeTransportModal={closeTransportModal}
        onSearch={searchTransportList}
        isLoading={isLoading}
        open={step === 1 && isOpenTransport}
      />
      {step === 2 && isOpenTransport && (
        <ResultCarModal
          isLoading={isLoading}
          autoList={autoList ? autoList : []}
          selectCar={selectCar}
          closeTransportModal={closeTransportModal}
        />
      )}
      {step === 3 && isOpenTransport && dicti && (
        <CardCarModalDynamic
          objAttributesList={objAttributesList}
          agrIsn={agrIsn}
          closeTransportModal={closeTransportModal}
          selectedCar={selectedCar}
          isLoading={isLoading}
          userForeignId={userForeignId}
          saveObject={saveObjectCar}
          objAttrData={objAttrData}
          productIsn={productIsn}
          carForm={carForm}
          AdvancedContractorSearch={AdvancedContractorSearch}
          productSelectedQuotation={productSelectedQuotation}
          modalSetting={modalSetting}
          saveObjectCond={saveObjectCond}
          primaryCondForm={primaryCondForm}
          additionalObjAttributesForm={additionalObjAttributesForm}
          additionalUnderForm={additionalUnderForm}
          agrCond={agrCond}
          dicti={dicti}
          quotationData={quotationData}
        />
      )}
      {step === 3 && isOpenModal && (
        <ObjectCardModalDynamic
          objAttributesList={objAttributesList}
          agrIsn={agrIsn}
          closeObjectModal={closeObjectModal}
          isLoading={isLoading}
          userForeignId={userForeignId}
          saveObject={saveObject}
          objAttrData={objAttrData}
          productIsn={productIsn}
          AdvancedContractorSearch={AdvancedContractorSearch}
          modalSetting={modalSetting}
          saveObjectCond={saveObjectCond}
          productSelectedQuotation={productSelectedQuotation}
          mainForm={mainForm}
          selectedObj={selectedObj}
          primaryCondForm={primaryCondForm}
          additionalObjAttributesForm={additionalObjAttributesForm}
          additionalUnderForm={additionalUnderForm}
          agrCond={agrCond}
          quotationData={quotationData}
          dicti={dicti}
          isHaveDicti={dicti?.length > 0}
        />
      )}
    </Card>
  );
};

export default ObjectQuotationDynamic;
